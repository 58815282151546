/**
 * @name: 小程序推荐人气教练
 * @author: itmobai
 * @date: 2023-06-03 10:27
 * @description：小程序推荐人气教练
 * @update: 2023-06-03 10:27
 */
import {get, postJ} from "@/request";
import {IMarketCoach} from "@/apis/marketingManage/coach/types";

/**
 * 查询推荐教练列表
 */
export const recommendtourQueryRecommendTourListApi = () => get<IMarketCoach[]>("/golf/recommendCourse/queryRecommendCourseList")
/**
 * 推荐热门教练修改
 * @param data
 */
export const recommendContestModifyApi = (data: Partial<IMarketCoach>) => postJ("/golf/recommendCourse/modify", data)
/**
 * 热门教练批量删除
 * @param ids
 */
export const recommendContestBatchDeleteApi = (ids: string) => get("/golf/recommendCourse/batchDelete", {ids})
/**
 * 教练上下架
 * @param id
 * @param status
 */
export const recommendContestUpAndDownShelfApi = (id: string, status: number) => postJ("/golf/recommendCourse/upAndDownShelf", {id,status})
/**
 * 创建推荐教练
 * @param data
 */
export const recommendContestCreateApi = (data: any) => postJ("/golf/recommendCourse/create", data)

